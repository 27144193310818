<script setup lang="ts"></script>

<template>
  <div class="flex cursor-pointer items-center">
    <div class="animated-underline font-script text-xl font-bold">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.animated-underline {
  position: relative;
}

.animated-underline:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: theme(colors.secondary);
  transform: scaleX(0);
  transition: transform 0.15s ease-in-out;
}

.animated-underline:hover:after {
  transform: scaleX(1);
}
</style>
