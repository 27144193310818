<script setup lang="ts">
// TODO - get this from the directus
const navOptions = [
  { title: "Home", path: "/" },
  { title: "Groups", path: "/groups" },
  { title: "Events", path: "/events" },
  { title: "News", path: "/news" },
  { title: "About", path: "/about" },
  { title: "Shop", path: "/shop" },
  { title: "Grants", path: "/tasmanian-nature-conservation-fund-grants/" },
  { title: "Contact", path: "/contact" },
];

const user = useUser();
</script>

<template>
  <CommonContainer>
    <div class="flex items-center justify-between gap-3">
      <NuxtLink class="z-10 mr-3" to="/">
        <img src="/logo.svg" alt="Wildcare Tasmania" class="my-[-10%] h-full w-24 lg:w-36" />
      </NuxtLink>
      <div class="mx-auto hidden items-center gap-8 xl:flex 2xl:gap-12">
        <NuxtLink v-for="item in navOptions" :key="item.title" :to="{ path: item.path }">
          <NavLink>{{ item.title }}</NavLink>
        </NuxtLink>
      </div>
      <div class="ml-auto flex items-center gap-3">
        <ClientOnly>
          <NuxtLink v-if="!user.user" class="px-4 py-3" :to="{ path: '/login' }">
            <NavLink class="text-black">Sign in</NavLink>
          </NuxtLink>
          <NavMenu v-else class="z-10 flex" />
        </ClientOnly>
        <NuxtLink class="ml-auto rounded bg-primary px-4 py-3" :to="{ path: '/donate' }">
          <NavLink class="text-white">Donate</NavLink>
        </NuxtLink>
        <NavMobileMenu :nav-options="navOptions" class="flex xl:hidden" />
      </div>
    </div>
  </CommonContainer>
</template>
