export default async function () {
  const directus = useNuxtApp().$directus;
  const router = useRouter();
  const route = useRoute();

  try {
    await directus.logout();
    await useGetUser();

    if (route.path === "/") {
      // If already on the homepage, reload the current page (as we have cleared nuxt data and need to re-make the calls)
      await router.go(0);
    } else {
      // If not on the homepage, navigate to it
      await router.push("/");
    }
  } catch (e: any) {
    let message;

    if (e.errors) {
      message = useDirectusError(e.errors[0]) || e.errors[0].message;
    } else {
      console.error(e);
      message = "An unknown error has occured, please try again.";
    }

    throw createError({
      statusCode: 400,
      fatal: true,
      message,
    });
  }
}
